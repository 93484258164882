import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import {
  ROPEnrollmentData,
  ROPParentConsentErrorData,
  ROPSignUpDataState,
} from "../../../models/models";
import { ResetUnlockBanner } from "../../../pages/reset-unlock/banner";
import { t } from "i18next";
import styles from "./enroll-success.module.scss";
import TGIcon from "../../../../../shared/tg-icon";
import { SuccessCard } from "../../../../../shared/success-card";
import consentappstore from "../../../../../../public/assets/consent_success_app_store.jpg";
import consentgoogleplay from "../../../../../../public/assets/consent_success_google_play.jpg";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../slice/RootReducer";
import ParentConsent from "../parent-consent";
import { updateLoginModalState } from "../../../slice/signUpSlice";
import { getLoginSectionDataPending } from "../../../slice/loginSlice";
import useScreenSize from "../../../../../utils/hook/useScreenSize";
import isAuthenticate from "../../../../../utils/hook/isAuthenticate";

const EnrollSuccess: React.FC = () => {
  const location = useLocation();
  const { enrollmentData } = location.state || {};
  const RopSIgnupResponse = useSelector(
    (state: RootState) => state?.osciReducer?.ropSignup
  );
  const name = `${enrollmentData?.firstName} ${enrollmentData?.lastName}`;
  const [memberId, setMemberID] = useState<string | null>("");
  const isDesktopView: boolean = useScreenSize().deviceSize.width > 767;
  const [currentDate, setCurrentDate] = useState<string>("");
  const response: any = useSelector(
    (state: RootState) => state?.osciReducer?.loginData
  );
  const password = sessionStorage.getItem("password");
  const memberID = sessionStorage.getItem("memberId");
  const isAuthenticated = isAuthenticate();
  const handlePanelShow = async () => {
    await dispatch(
      getLoginSectionDataPending({
        memberId: memberID,
        password: password,
      })
    );
  };
  const dispatch = useDispatch();

  const [ropAlreadyExist, setRopAlreadyExist] = useState<boolean>(false);
  useEffect(() => {
    if (response.hasData) {
      dispatch(updateLoginModalState({ value: true }));
    }
  }, [response.hasData]);
  useEffect(() => {
    const date = new Date();
    const day = date.getDate();
    const month = date.toLocaleString("default", { month: "long" });
    const year = date.getFullYear();
    const formattedDate = `${day} ${month} ${year}`;
    setCurrentDate(formattedDate);
  }, []);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  useEffect(() => {
    const memberId = sessionStorage.getItem("memberId");
    setMemberID(memberId);
  });
  const handleAppStoreClick = () => {
    window.location.href =
      "https://apps.apple.com/th/app/thai-airways/id1393912966";
  };
  const handleGooglePlayClick = () => {
    window.location.href =
      "https://play.google.com/store/apps/details?id=com.thaiairways.mobile&hl=en&gl=US";
  };
  // console.log("RopSIgnupResponse.memberId", enrollmentData?.memberId);
  // state.ropStatus.memberId
  // const response = useSelector((state: RootState) => state?.osciReducer?.);
  // console.log("state new", response.memberId);
  return (
    <>
      {isDesktopView && (
        <ResetUnlockBanner
          imageText={t("label_consent_success_title")}
          descriptionText1={t("label_enrolment_consent_description1")}
          descriptionText2={t("label_enrolment_consent_description2")}
        >
          {" "}
          <div className={styles.welcome}>
            {t("label_consent_success_welcome")}
          </div>
          <div className={styles.bgContainer}>
            <div className={styles.container}>
              <div>
                <div>
                  <div>
                    <div>
                      <span>{memberId}</span>
                      <span>{name}</span>
                    </div>
                    <div>
                      <span>{t("label_consent_success_miles")}</span>
                    </div>
                  </div>
                  {/* <div>{name}</div> */}
                  <div>
                    <span>{t("label_consent_success_member")}</span>
                    <span>{currentDate}</span>
                  </div>
                </div>
              </div>
              <div>
                <span>{t("label_consent_success_download")}</span>
                <div>
                  <div onClick={handleAppStoreClick}>
                    <img src={consentappstore} />
                  </div>
                  <div onClick={handleGooglePlayClick}>
                    <img src={consentgoogleplay} />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className={styles.bottomContainer}>
            <div className={styles.thankyouNote}>
              {t("label_consent_success_thanks")}
            </div>
            <div className={styles.signinCard}>
              <SuccessCard
                iconName="user-icon"
                text={t("label_signin_header")}
                redirection="sidebar"
                handlePanelShow={handlePanelShow}
                path="enrolment"
              />
            </div>
          </div>
        </ResetUnlockBanner>
      )}
      {!isDesktopView && (
        <ResetUnlockBanner
          imageText={t("label_consent_success_title")}
          descriptionText2={t("label_enrolment_consent_description2")}
        >
          {" "}
          <div className={styles.welcome}>
            {t("label_consent_success_welcome")}
          </div>
          <div className={styles.bgContainerMobile}>
            <div className={styles.mobileContainer}>
              <div>
                <div>
                  <div>
                    <div>
                      <span>{memberId}</span>
                      <span>{name}</span>
                    </div>
                    <div>
                      <span>{t("label_consent_success_miles")}</span>
                    </div>
                  </div>
                  <div>
                    <span>{t("label_consent_success_member")}</span>
                    <span>{currentDate}</span>
                  </div>
                </div>
              </div>
              <div>
                <span>{t("label_consent_success_download")}</span>
                <div>
                  <div>
                    <img src={consentappstore} />
                  </div>
                  <div>
                    <img src={consentgoogleplay} />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className={styles.mobileBottomContainer}>
            <div className={styles.mobilethankyouNote}>
              {t("label_consent_success_thanks")}
            </div>
            <div className={styles.mobileSigninCard}>
              <SuccessCard
                iconName="user-icon"
                text={t("label_signin_header")}
                redirection="sidebar"
                handlePanelShow={handlePanelShow}
                path="enrolment"
              />
            </div>
          </div>
        </ResetUnlockBanner>
      )}
    </>
  );
};

export default EnrollSuccess;
