import React from "react";
import TGIcon from "../../../../shared/tg-icon";
import TGDateDropdown from "../../../../shared/tg-date-dropdown";
import TGInput from "../../../../shared/tg-input";
import TGSelection from "../../../../shared/tg-selection";
import styles from "./signup.module.scss";
import { useEffect, useRef, useState } from "react";
import TGCheckBox from "../../../../shared/tg-checkbox";
import { Typeahead } from "react-bootstrap-typeahead";
import mealData from "../../../../../public/json/meal.json";
import TGButtonVariants from "../../../../shared/tg-button-variants";
import useScreenSize from "../../../../utils/hook/useScreenSize";
import { Form, InputGroup, Placeholder, Spinner } from "react-bootstrap";
import { Preference, ROPEnrollmentData } from "../../models/models";
import { t } from "i18next";
import moment from "moment";
import { getRopSignUpDataPending } from "../../slice/ropSignUpSlice";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../slice/RootReducer";
import { useLocation, useNavigate } from "react-router-dom";
import i18n from "i18next";
import LoginWidget from "../../../../shared/login-widget";
import termConditionData from "../../../../../public/json/termConditions.json";
import { el } from "date-fns/locale";
import { count } from "console";
import DatePickerCalendar from "../../../../shared/tg-calendar";
import { formatContact, isvalidContact } from "../../../../utils/helper";
import {
  fetchCRMProfileRequest,
  fetchCountryRequest,
  fetchCityRequest,
  fetchStateRequest,
} from "../../slice/fetchProfileSlice";
import TGTypeahead from "../../../../shared/tg-typeahead";

export const Enrollment = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const [forceClose, setForceClose] = useState(false);
  const [showParentConsent, setShowParentConsent] = useState(false);
  const [state, setState] = useState({
    title: "",
  });
  let isDesktopView: boolean = useScreenSize().deviceSize.width > 767;
  type Country = {
    name: string;
    code: string;
    emoji: string;
    unicode: string;
    dial_code: string;
    image: string;
    nationality: string;
  };
  type State = {
    name: string;
    state_code: string;
    country_code: string;
  };
  type City = {
    country_code: string;
    country_name: string;
    city_code: string;
    name: string;
    state_code: string;
  };

  const [date, setDate] = useState("");
  const [month, setMonth] = useState("");
  const [year, setYear] = useState("");
  const [ropAlreadyExist, setRopAlreadyExist] = useState<boolean>(false);
  const [wentWrong, setWentWrong] = useState<boolean>(false);
  const [otherCity, setOtherCity] = useState("");
  const [defaultPreference, setDefaultPreference] = useState<Preference>({
    seat: "No Preference",
    meal: "No Preference",
    language: "English",
  });
  const [enrollmentData, setEnrollementData] = useState<ROPEnrollmentData>({
    title: "",
    firstName: "",
    lastName: "",
    dob: "",
    gender: "",
    languagePreference: "E",
    addressLine1: "",
    addressLine2: "",
    country: "",
    countryCode: "",
    countryCodeSecondary: "",
    city: "",
    otherCity: "",
    state: "",
    postalCode: "",
    contactNumber: "",
    enrollDate: "",
    email: "",
    confirmEmail: "",
    createPassword: "",
    confirmPassword: "",
    referringMemberID: "",
    seatPreference: "NNN",
    seatPreferenceValue: "",
    languagePreferenceValue: "",
    mealPreferenceValue: "",
    mealPreference: "NNN",
    termsConditions: "",
    ropNewsCheck: "N",
    thirdPartyCheck: "N",
    consentParentFirstName: "",
    consentParentLastName: "",
    preference: "",
  });
  const urlCountry = i18n.language?.split("-")?.[1]?.toUpperCase();
  const [selectedCountryValue, setSelectedCountryValue] = useState(null);
  const [isPreferenceDisabled, setIsPreferenceDisabled] = useState(true);
  const [isPrimaryChecked, setIsPrimaryChecked] = useState<boolean>(false);
  const [isToggleOpen, setIsToggleOpen] = useState<string>("");

  const [countryData, setCountryData] = useState([]);
  const [cityData, setCityData] = useState([]);
  const [stateData, setStateData] = useState([]);

  const countryResponse = useSelector(
    (state: RootState) => state?.osciReducer?.profile.countryData
  );
  const cityResponse = useSelector(
    (state: RootState) => state?.osciReducer?.profile.cityData
  );
  const stateResponse = useSelector(
    (state: RootState) => state?.osciReducer?.profile.stateData
  );

  useEffect(() => {
    dispatch(fetchCountryRequest());
    dispatch(fetchCityRequest());
    dispatch(fetchStateRequest());
  }, [dispatch]);

  useEffect(() => {
    setCountryData(countryResponse);
    setCityData(cityResponse);
    setStateData(stateResponse);
  }, [cityResponse, countryResponse, stateResponse]);

  useEffect(() => {
    if (selectedCountryValue && isPrimaryChecked) {
      setIsPreferenceDisabled(false);
    }
  }, [setIsPreferenceDisabled]);

  useEffect(() => {
    const countryCode = countryData?.find(
      (country) => country.name === enrollmentData.country
    );
    setEnrollementData({
      ...enrollmentData,
      countryCodeSecondary: countryCode?.code,
    });
    setErrors({ ...errors, countryCodeSecondary: "" });
  }, [enrollmentData.country]);
  useEffect(() => {}, [
    enrollmentData.termsConditions,
    enrollmentData.title,
    enrollmentData.gender,
  ]);
  const [selectedCountry, setSelectedCountry] = useState<Country | null>(null);

  const [selectPreferenceCountry, setSelectPreferencecCountry] =
    useState<Country | null>(null);
  const [showSignIn, setShowSignIn] = useState(false);
  const [selectedState, setSelectedState] = useState<State | null>(null);
  const [selectedCity, setSelectedCity] = useState<City | any>(null);
  const [selectedCountryCode, setSelectedCountryCode] =
    useState<Country | null>(null);
  const [isContactDisabled, setIsContactDisabled] = useState<boolean>(false);
  const [countryCodeValue, setCountryCodeValue] = useState(" ");
  const [filteredStates, setFilteredStates] = useState<State[]>([]);
  const [filteredCities, setFilteredCities] = useState<City[]>([]);
  const RopSIgnupResponse = useSelector(
    (state: RootState) => state?.osciReducer?.ropSignup
  );
  const crmProfileResponse = useSelector(
    (state: RootState) => state?.osciReducer?.profile.crmProfileData
  );
  const monthNames: string[] = [
    t("label_dob_calendar_january"),
    t("label_dob_calendar_february"),
    t("label_dob_calendar_march"),
    t("label_dob_calendar_april"),
    t("label_dob_calendar_may"),
    t("label_dob_calendar_june"),
    t("label_dob_calendar_july"),
    t("label_dob_calendar_august"),
    t("label_dob_calendar_september"),
    t("label_dob_calendar_october"),
    t("label_dob_calendar_november"),
    t("label_dob_calendar_december"),
  ];

  const handleMonthFormat = (month: string) => {
    const monthValue = monthNames.findIndex((x: any) => x === month);
    let correctMonthValue = monthValue + 1;
    let formattedMonthValue = "";

    if (correctMonthValue < 10) {
      formattedMonthValue = `0${correctMonthValue.toString()}`;
    } else {
      formattedMonthValue = correctMonthValue.toString();
    }
    return formattedMonthValue;
  };

  useEffect(() => {
    if (sessionStorage.getItem("isROPLoggedIn") === "false") {
      dispatch(fetchCRMProfileRequest());
    }
  }, [dispatch]);
  const [formattedDob, setFormattedDob] = useState("");
  useEffect(() => {
    let formatDob = moment(crmProfileResponse?.dob).format("YYYY-MMMM-DD");
    let splitdob = crmProfileResponse?.dob?.split("-");
    if (splitdob != undefined && splitdob != null) {
      setDate(splitdob[2]);
      setMonth(splitdob[1]);
      setYear(splitdob[0]);
    }
    setFormattedDob(formatDob);
    setEnrollementData({
      ...enrollmentData,
      title: crmProfileResponse.salutation,
      firstName: crmProfileResponse.firstName,
      lastName: crmProfileResponse.lastName,
      dob: formatDob,
      email: crmProfileResponse.emailId,
      contactNumber: crmProfileResponse.mobileNumber,
      confirmEmail: crmProfileResponse.emailId,
    });
  }, [crmProfileResponse]);
  useEffect(() => {
    if (selectedCountryCode) {
      setCountryCodeValue(selectedCountryCode.dial_code);
    } else {
      if (selectedCountry) {
        setCountryCodeValue(selectedCountry.dial_code);
      }
      if (selectedCountry === null) {
        setCountryCodeValue("");
        setIsContactDisabled(false);
      }
    }
  }, [selectedCountry, enrollmentData.country, selectedCountryCode]);
  useEffect(() => {
    if (RopSIgnupResponse.ropStatus.message == "Duplicate") {
      setRopAlreadyExist(true);
      setWentWrong(false);
    } else if (RopSIgnupResponse.ropStatus.message == "Something went wrong") {
      setRopAlreadyExist(false);
      setWentWrong(true);
    } else {
      if (RopSIgnupResponse.ropStatus.message == "Member Added") {
        setRopAlreadyExist(false);
        navigate(`/${i18n.language}/enrolment/success`, {
          state: { enrollmentData },
        });
      }
    }
  }, [RopSIgnupResponse]);

  const preferenceFromResponse = useSelector(
    (state: RootState) => state?.osciReducer?.preferenceFrom.countryList
  );

  var res: any;

  useEffect(() => {
    const urlCountry = i18n.language?.split("-")?.[1]?.toUpperCase();

    res = preferenceFromResponse.find(
      (el: { code: string }) => el.code === urlCountry
    );
    if (res !== null && res !== undefined) {
      setSelectedCountryValue(res);
      setEnrollementData({ ...enrollmentData, preference: res?.country });
    }
  }, [preferenceFromResponse]);

  useEffect(() => {
    if (selectedCountry) {
      setEnrollementData({
        ...enrollmentData,
        countryCode: selectedCountry?.dial_code,
      });
      setErrors({ ...errors, countryCode: "" });
      const statePresent = stateData.filter(
        (state) => state.country_code === selectedCountry.code
      );
      if (statePresent.length > 0) {
        setIsDisabled(false);
        setIsDisabledState(false);
      } else {
        setIsDisabledState(true);
        setIsDisabled(false);
      }

      setFilteredStates(
        stateData.filter((state) => state.country_code === selectedCountry.code)
      );
      setFilteredCities(
        cityData.filter(
          (city: any) => city.country_code === selectedCountry.code
        )
      );
    }
  }, [selectedCountry, enrollmentData.country]);

  const handlePreferredCountryChange = (selected: any) => {
    const country = selected.length ? selected[0] : null;
    setSelectedCountryValue(country);
    setErrors({ ...errors, preference: "" });
  };

  const handleCountryChange = (selected: any) => {
    const country = selected.length ? selected[0] : null;
    setSelectedCountry(country);
    setSelectedState(null);
    setSelectedCity(null);
    setEnrollementData({ ...enrollmentData, country: country?.name });
    setErrors({ ...errors, country: "" });
    setIsContactDisabled(true);
    setIsToggleOpen("");
  };

  const handleCountryCodeChange = (selected: any) => {
    const country = selected.length ? selected[0] : null;
    setSelectedCountryCode(country);
    setCountryCodeValue(country?.dial_code);
    setEnrollementData({ ...enrollmentData, countryCode: country?.dial_code });
    setErrors({ ...errors, countryCode: "" });
    setIsToggleOpen("");
  };

  const handleStateChange = (selected: any) => {
    const state = selected.length ? selected[0] : null;
    setSelectedState(state);
    setEnrollementData({ ...enrollmentData, state: state?.name });
    setErrors({ ...errors, state: "" });
    setIsToggleOpen("");
  };

  const handleCityChange = (selected: any) => {
    const city = selected.length ? selected[0] : null;
    setSelectedCity(city);
    if (city?.name === "Other (Please specify)") {
      setEnrollementData({ ...enrollmentData, city: otherCity });
      setErrors({ ...errors, city: "" });
    } else {
      setEnrollementData({ ...enrollmentData, city: city?.name });
      setErrors({ ...errors, city: "" });
    }
    setIsToggleOpen("");
  };

  const handleOtherCityChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    if (name === "otherCity") {
      setOtherCity(value);
      setEnrollementData({ ...enrollmentData, city: value });
      setErrors({ ...errors, otherCity: "" });
    }
  };
  useEffect(() => {
    // if (res !== null && res !== undefined) {
    //   setSelectedCountryValue(res);
    //   setEnrollementData({ ...enrollmentData, preference: res?.country });
    // }
    if (location.state && location.state.enrollmentData) {
      setIsDisabled(false);
      setEnrollementData(location.state.enrollmentData);
      const previousCountryState = countryData?.find(
        (x: any) => x.name === location.state.enrollmentData.country
      );
    }
  }, []);
  const [isDisabled, setIsDisabled] = useState(true);
  const [isDisabledState, setIsDisabledState] = useState(true);
  const [isLessThanSixteen, setIsLessThanSixteen] = useState(false);
  const [errors, setErrors] = useState<Partial<ROPEnrollmentData>>({});
  const [criteria, setCriteria] = useState({
    minLength: false,
    hasNumber: false,
    hasAlphabet: false,
    hasSpecialChar: true,
  });

  useEffect(() => {
    if (enrollmentData.createPassword !== "") {
      const minLength = enrollmentData.createPassword.length >= 8;
      const hasSpecialChar = /[!@#$%^&*(),.?:{}|<>"]/.test(
        enrollmentData.createPassword
      );
      const hasNumber = /\d/.test(enrollmentData.createPassword);
      const hasAlphabet = /[a-zA-Z]/.test(enrollmentData.createPassword);
      setCriteria({
        minLength,
        hasSpecialChar,
        hasNumber,
        hasAlphabet,
      });
    } else {
      setCriteria({
        minLength: false,
        hasNumber: false,
        hasAlphabet: false,
        hasSpecialChar: true,
      });
    }
  }, [enrollmentData.createPassword]);

  function scrollToErrorFields(errorKeys: (keyof ROPEnrollmentData)[]) {
    let currentIndex = 0;

    function scrollToNextError() {
      if (currentIndex >= errorKeys.length) return;

      const key = errorKeys[currentIndex];
      let element: HTMLElement | null = null;

      element =
        document.querySelector<HTMLInputElement>(`input[name="${key}"]`) ||
        document.querySelector('div[class~="input-group"]');

      if (element) {
        element.scrollIntoView({ behavior: "smooth", block: "center" });
        currentIndex++;
      } else {
        currentIndex++;
        scrollToNextError(); // Move to the next field if the current one isn't found
      }
    }
    scrollToNextError();
  }

  const handleSubmitEnroll = (event: React.FormEvent<HTMLButtonElement>) => {
    if (formattedDob != "") {
      let splitdob = formattedDob.split("-");
      let monthFormat = handleMonthFormat(splitdob[1]);
      setDate(splitdob[2]);
      setMonth(monthFormat);
      setYear(splitdob[0]);
      let dob = `${year}-${month}-${date}`;
      setEnrollementData({ ...enrollmentData, dob: dob });
    }
    const newErrors: Partial<ROPEnrollmentData> = {};
    Object.entries(enrollmentData).forEach(([key, value]) => {
      if (!value?.trim()) {
        newErrors[key as keyof ROPEnrollmentData] = getFieldLabel(
          key as keyof ROPEnrollmentData
        );
      } else if (
        (key === "firstName" && enrollmentData?.firstName?.length < 2) ||
        (key === "lastName" && enrollmentData?.lastName?.length < 2)
      ) {
        newErrors[key as keyof ROPEnrollmentData] =
          key === "firstName"
            ? t("error_min_length_first_name")
            : t("error_min_length_last_name");
      } else if (
        (key === "consentParentFirstName" &&
          enrollmentData?.consentParentFirstName?.length < 2) ||
        (key === "consentParentLastName" &&
          enrollmentData?.consentParentLastName?.length < 2)
      ) {
        newErrors[key as keyof ROPEnrollmentData] =
          key === "consentParentFirstName"
            ? t("error_min_length_first_name")
            : t("error_min_length_last_name");
      } else if (key === "email" && !isValidEmail(value)) {
        newErrors[key as keyof ROPEnrollmentData] = t("reset_invalid_message");
      } else if (key === "confirmEmail" && !isEmailMatch(value)) {
        if (newErrors["email"] !== t("reset_invalid_message")) {
          newErrors[key as keyof ROPEnrollmentData] = t(
            "enroll_email_mismatch"
          );
        }
      } else if (key === "createPassword" && !isValidPasswordFormat()) {
        newErrors[key as keyof ROPEnrollmentData] = t(
          "enroll_password_invalid"
        );
      } else if (key === "confirmPassword" && !isPasswordMatch(value)) {
        newErrors[key as keyof ROPEnrollmentData] = t(
          "enroll_password_mismatch"
        );
      } else if (key == "dob") {
        if (date == "") {
          newErrors[key as keyof ROPEnrollmentData] = t("enroll_date_required");
        } else if (month == "00") {
          newErrors[key as keyof ROPEnrollmentData] = t(
            "enroll_month_required"
          );
        } else if (year == "") {
          newErrors[key as keyof ROPEnrollmentData] = t("enroll_year_required");
        }
        if (date != "" && month != "" && year != "") {
          const dobDate = moment(enrollmentData.dob, "YYYY-MM-DD");
          const minAllowedDate = moment().subtract(2, "years");
          var validDate = moment(enrollmentData.dob);
          if (dobDate.isAfter(minAllowedDate)) {
            newErrors[key as keyof ROPEnrollmentData] = t(
              "enroll_age_validation"
            );
          } else if (!validDate.isValid()) {
            newErrors[key as keyof ROPEnrollmentData] = t(
              "label_valid_dob_err"
            );
          }
        }
      } else if (
        (key === "consentParentFirstName" &&
          enrollmentData?.consentParentFirstName?.length < 2) ||
        (key === "consentParentLastName" &&
          enrollmentData?.consentParentLastName?.length < 2)
      ) {
        newErrors[key as keyof ROPEnrollmentData] =
          key === "consentParentFirstName"
            ? t("error_min_length_first_name")
            : t("error_min_length_last_name");
      }
      if (key === "preference") {
        if (!isPreferenceDisabled && selectedCountryValue !== null) {
          newErrors[key as keyof ROPEnrollmentData] = t("");
        }
      }
      if (key === "countryCode" && enrollmentData.countryCode === "") {
        newErrors[key as keyof ROPEnrollmentData] = t(
          "label_country_code_error"
        );
      }
      if (key === "contactNumber" && enrollmentData.contactNumber.length < 3) {
        newErrors[key as keyof ROPEnrollmentData] = t(
          "label_country_code_error"
        );
      }
    });
    setErrors(newErrors);
    const dobDate = moment(enrollmentData.dob, "YYYY-MM-DD");
    if (errors.termsConditions == "error") {
      setErrors({ ...newErrors, termsConditions: "error" });
      newErrors["termsConditions"] = "error";
    }

    if (enrollmentData.gender != "") {
      const genderTitleMap: { [key: string]: string[] } = {
        Male: [t("enroll_mr"), t("enroll_mister")],
        Female: [t("enroll_mrs"), t("enroll_ms"), t("enroll_miss")],
        Unspecified: [t("enroll_title_prefer_not")],
        Undisclosed: [t("enroll_title_prefer_not")],
      };
      const validTitle = genderTitleMap[enrollmentData.gender];
      if (!validTitle || !validTitle.includes(enrollmentData.title)) {
        newErrors["gender"] = t("enroll_gender_error");
        setErrors({ ...newErrors, gender: t("enroll_gender_error") });
      }
    }
    if (enrollmentData.dob != "" && enrollmentData.title != "") {
      const lessThan12 = moment().subtract(12, "years");
      if (
        dobDate?.isAfter(lessThan12) &&
        (enrollmentData.title == t("enroll_mr") ||
          enrollmentData.title == t("enroll_mrs") ||
          enrollmentData.title == t("enroll_ms"))
      ) {
        newErrors["title"] = t("enroll_title_error");
        setErrors({ ...newErrors, title: t("enroll_title_error") });
      }
      if (
        dobDate?.isBefore(lessThan12) &&
        (enrollmentData.title == t("enroll_miss") ||
          enrollmentData.title == t("enroll_mister"))
      ) {
        newErrors["title"] = t("enroll_adult_greater_12");
        setErrors({ ...newErrors, title: t("enroll_adult_greater_12") });
      }
    }
    const lessThan16 = moment().subtract(16, "years");
    const keys = Object.keys(newErrors) as (keyof ROPEnrollmentData)[];
    if (!isPrimaryChecked) {
      const index = keys.indexOf("preference", 0);
      if (index > -1) {
        keys.splice(index, 1);
      }
    }

    if (selectedCountryValue !== "" && selectedCountryValue !== null) {
      const index = keys.indexOf("preference", 0);
      if (index > -1) {
        keys.splice(index, 1);
      }
    }
    var filterUndefinedError;
    if (dobDate?.isAfter(lessThan16)) {
      if (
        enrollmentData.ropNewsCheck == "N" &&
        enrollmentData.thirdPartyCheck == "N"
      ) {
        filterUndefinedError = keys.filter(
          (key) =>
            newErrors[key] !== undefined &&
            key != "consentParentFirstName" &&
            key != "consentParentLastName"
        );
      } else {
        filterUndefinedError = keys.filter(
          (key) => newErrors[key] !== undefined
        );
      }
    } else {
      filterUndefinedError = keys.filter(
        (key) =>
          newErrors[key] !== undefined &&
          key != "consentParentFirstName" &&
          key != "consentParentLastName"
      );
    }
    if (filterUndefinedError.length === 0) {
      let genderValue;
      if (enrollmentData.gender == "Male") {
        genderValue = "M";
      } else if (enrollmentData.gender == "Female") {
        genderValue = "F";
      } else if (enrollmentData.gender == "Unspecified") {
        genderValue = "U";
      } else if (enrollmentData.gender == "Undisclosed") {
        genderValue = "U";
      }
      if (
        (isPrimaryChecked || isSecondaryChecked) &&
        dobDate?.isAfter(lessThan16)
      ) {
        const moment = require("moment");
        const formattedDate = moment().format("YYYY-MM-DD");
        dispatch(
          getRopSignUpDataPending({
            title: enrollmentData.title,
            gender: genderValue,
            firstName: enrollmentData.firstName,
            lastName: enrollmentData.lastName,
            dob: enrollmentData.dob,
            languagePreference: enrollmentData.languagePreference,
            addressLine1: enrollmentData.addressLine1,
            addressLine2: enrollmentData.addressLine2,
            country: enrollmentData.country,
            email: enrollmentData.email,
            countryCode: enrollmentData.countryCode,
            countryCodeSecondary: selectedCountry?.code,
            enrollDate: formattedDate,
            contactNumber: enrollmentData.contactNumber?.replaceAll("-", ""),
            city: enrollmentData.city,
            state: enrollmentData.state,
            postalCode: enrollmentData.postalCode,
            createPassword: enrollmentData.createPassword,
            referringMemberID: enrollmentData.referringMemberID,
            seatPreference: enrollmentData.seatPreference,
            mealPreference: enrollmentData.mealPreference,
            termsConditions: enrollmentData.termsConditions,
            ropNewsCheck: enrollmentData.ropNewsCheck,
            thirdPartyCheck: enrollmentData.thirdPartyCheck,
            consentParentFirstName: enrollmentData.consentParentFirstName,
            consentParentLastName: enrollmentData.consentParentLastName,
            cityCode: isPrimaryChecked
              ? selectedCountryValue?.airportCityCode
              : "",
          })
        );
      } else {
        const moment = require("moment");
        const formattedDate = moment().format("YYYY-MM-DD");
        dispatch(
          getRopSignUpDataPending({
            title: enrollmentData.title,
            gender: genderValue,
            firstName: enrollmentData.firstName,
            lastName: enrollmentData.lastName,
            dob: enrollmentData.dob,
            languagePreference: enrollmentData.languagePreference,
            addressLine1: enrollmentData.addressLine1,
            addressLine2: enrollmentData.addressLine2,
            country: enrollmentData.country,
            email: enrollmentData.email,
            countryCode: enrollmentData.countryCode,
            countryCodeSecondary: selectedCountry?.code,
            enrollDate: formattedDate,
            contactNumber: enrollmentData.contactNumber,
            city: enrollmentData.city,
            state: enrollmentData.state,
            postalCode: enrollmentData.postalCode,
            createPassword: enrollmentData.createPassword,
            referringMemberID: enrollmentData.referringMemberID,
            seatPreference: enrollmentData.seatPreference,
            mealPreference: enrollmentData.mealPreference,
            termsConditions: enrollmentData.termsConditions,
            ropNewsCheck: enrollmentData.ropNewsCheck,
            thirdPartyCheck: enrollmentData.thirdPartyCheck,
            cityCode: isPrimaryChecked
              ? selectedCountryValue?.airportCityCode
              : "",
          })
        );
      }
    }
    // Call the function to scroll through all error fields
    if (filterUndefinedError.length > 0) {
      scrollToErrorFields(filterUndefinedError);
    }
  };

  const [isChecked, setIsChecked] = useState<boolean>(false);

  const [isSecondaryChecked, setIsSecondaryChecked] = useState<boolean>(false);
  const termConditionRef = useRef<any>(null);
  const isValidEmail = (email: string) => {
    return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
  };
  const isEmailMatch = (email: string) => {
    if (email !== enrollmentData.email) {
      return false;
    }
    return true;
  };

  const isValidPasswordFormat = () => {
    if (
      !criteria.hasAlphabet ||
      !criteria.hasNumber ||
      !criteria.minLength ||
      criteria.hasSpecialChar
    ) {
      return false;
    }
    return true;
  };
  const isPasswordMatch = (password: string) => {
    if (password !== enrollmentData.createPassword) {
      return false;
    }
    return true;
  };
  const handleSignInPopupShow = (): void => {
    setShowSignIn(!showSignIn);
  };
  const handleChange = (event: any) => {
    const { name, value } = event.target;
    if (name !== "contactNumber") {
      setEnrollementData({
        ...enrollmentData,
        [name]: name === "referringMemberID" ? value?.toUpperCase() : value,
      });
    } else if (name === "contactNumber" && isvalidContact(value)) {
      setEnrollementData({
        ...enrollmentData,
        contactNumber: formatContact(value),
      });
      setErrors({ ...errors, countryCode: "", countryCodeSecondary: "" });
    }
    setErrors({ ...errors, [name]: "" });
  };
  // const handleKeyPress = (event: any)=>{

  //   const charCode = event.charCode;
  //   if(charCode < 48 || charCode > 57){
  //     event.preventDefault();
  //   }

  // }
  const handleDateChange = (date: string, month: string, year: string) => {
    setFormattedDob("");
    const formattedDateOfBirth = `${year}-${month}-${date}`;
    setEnrollementData({ ...enrollmentData, dob: formattedDateOfBirth });
    setErrors({ ...errors, dob: "" });
    setDate(date);
    setMonth(month);
    setYear(year);
    if (date == "") {
      setErrors({ ...errors, dob: t("enroll_date_required") });
    } else if (month == "00") {
      setErrors({ ...errors, dob: t("enroll_month_required") });
    } else if (year == "") {
      setErrors({ ...errors, dob: t("enroll_year_required") });
    }
    if (date != "" && month != "" && year != "") {
      const dobDate = moment(formattedDateOfBirth, "YYYY-MM-DD");
      const minAllowedDate = moment().subtract(2, "years");
      var validDate = moment(formattedDateOfBirth);
      if (dobDate.isAfter(minAllowedDate)) {
        setErrors({ ...errors, dob: t("enroll_age_validation") });
      } else if (!validDate.isValid()) {
        setErrors({ ...errors, dob: t("label_valid_dob_err") });
      }
    }
  };
  const checkedChange = (e: any): void => {
    setIsChecked(!isChecked);
    if (termConditionRef.current) {
      const value = termConditionRef.current.checked;
      setEnrollementData({
        ...enrollmentData,
        termsConditions: value.toString(),
      });
      if (value == true) {
        setErrors({ ...errors, termsConditions: "" });
      } else {
        setErrors({ ...errors, termsConditions: "error" });
      }
    }
  };
  useEffect(() => {
    const dobDate = moment(enrollmentData.dob, "YYYY-MM-DD");
    const lessThan16 = moment().subtract(16, "years");
    dobDate?.isAfter(lessThan16)
      ? setIsLessThanSixteen(true)
      : setIsLessThanSixteen(false);
  }, [
    enrollmentData.ropNewsCheck,
    enrollmentData.thirdPartyCheck,
    enrollmentData.dob,
  ]);
  const checkedPrimaryChange = (e: any): void => {
    setIsPrimaryChecked((prevState: any) => !prevState);

    setIsPreferenceDisabled(isPrimaryChecked);
    if (isPrimaryChecked) {
      setSelectedCountryValue(null);
      setEnrollementData({ ...enrollmentData, preference: "" });
    }
    setErrors({ ...errors, preference: "" });
    setErrors({ ...errors, ropNewsCheck: "" });
  };

  useEffect(() => {
    setEnrollementData({
      ...enrollmentData,
      ropNewsCheck: isPrimaryChecked ? "D" : "N",
    });
  }, [isPrimaryChecked]);
  const checkedSecondaryChange = (e: any): void => {
    setIsSecondaryChecked(!isSecondaryChecked);
    setErrors({ ...errors, thirdPartyCheck: "" });
  };

  useEffect(() => {
    setEnrollementData({
      ...enrollmentData,
      thirdPartyCheck: isSecondaryChecked ? "D" : "N",
    });
  }, [isSecondaryChecked]);
  const getFieldLabel = (fieldName: keyof ROPEnrollmentData) => {
    const labels: { [key in keyof ROPEnrollmentData]: string } = {
      title: t("enroll_title_required"),
      gender: t("enroll_gender_required"),
      firstName: t("signin_email_required"),
      lastName: t("signin_email_required"),
      dob: t("error_reset_dob_required"),
      addressLine1: t("signin_email_required"),
      country: t("signin_email_required"),
      countryCode: t("signin_email_required"),
      contactNumber: t("signin_email_required"),
      city:
        selectedCity === "Other (Please specify)"
          ? ""
          : t("signin_email_required"),
      otherCity:
        selectedCity === "Other (Please specify)" && otherCity.trim() === ""
          ? t("error_enroll_other_city_province")
          : undefined,
      postalCode: t("signin_email_required"),
      email: t("signin_email_required"),
      confirmEmail: t("enroll_confirm_email_required"),
      createPassword: t("signin_password_required"),
      confirmPassword: t("enroll_confirm_required"),
      termsConditions: t("signin_email_required"),
      consentParentFirstName: t("signin_email_required"),
      consentParentLastName: t("signin_email_required"),
      preference: t("label_preferred_news_rop_err_msg"),
    };
    return labels[fieldName];
  };
  const onChange = (e: any, label: any) => {
    const formattedDate = moment(e).format("YYYY-MM-DD");
    setEnrollementData({ ...enrollmentData, dob: formattedDate });
    setErrors({ ...errors, dob: "" });
  };
  const onSelect = (e: any, obj: any) => {
    const { id } = e.target;
    setState({ ...state, [id]: obj.listName });
    const { name, value } = e.target;
  };
  const setTitleState = (e: any, obj: any) => {
    setEnrollementData({ ...enrollmentData, title: obj.listName });
    setErrors({ ...errors, title: "" });
  };
  const setGenderState = (e: any, obj: any) => {
    setEnrollementData({ ...enrollmentData, gender: obj.listName });
    setErrors({ ...errors, gender: "" });
  };
  const setLanguageState = (e: any, obj: any) => {
    setEnrollementData({ ...enrollmentData, languagePreference: obj.listAbbr });
    setErrors({ ...errors, languagePreference: "" });
  };
  const handleSignInPopupClose = (callback?: Function): void => {
    setShowSignIn(false);
    if (callback) {
      callback();
    }
    navigate(window.location.origin);
  };
  const setSeatState = (e: any, obj: any) => {
    setEnrollementData({ ...enrollmentData, seatPreference: obj.listAbbr });
    setErrors({ ...errors, seatPreference: "" });
  };
  const setMealState = (e: any, obj: any) => {
    setEnrollementData({ ...enrollmentData, mealPreference: obj.listAbbr });
    setErrors({ ...errors, mealPreference: "" });
  };

  const handleKeyDown = (e: any) => {
    const isValidInput = /^[a-zA-Z ]{0,100}$/.test(e.key);
    if (!isValidInput) {
      e.preventDefault();
    }
  };

  const handleCountryKeyDown = (e: any) => {
    const isValidInput = /^[a-zA-Z0-9+ ]{0,100}$/.test(e.key);
    if (!isValidInput) {
      e.preventDefault();
    }
  };

  return (
    <>
      {isDesktopView && (
        <div className={styles.outterEnrollmentWrapper}>
          <div className={styles.outterEnrollWrapper}>
            <div className={styles.outterEnroll}>
              <div className={styles.outterEnroll}>
                <div className={styles.headingContainer}>
                  <div className={styles.title}>
                    {t("enroll_personal_Info")}
                  </div>
                  <div className={styles.description}>
                    {t("enroll_rop_description")}{" "}{t("enroll_age_validation_2_yrs")}
                  </div>
                  {ropAlreadyExist && (
                    <div className={styles.alreadyExistContainer}>
                      <TGIcon icon="error-icon" fillColor="" size="16" />
                      <div className={styles.alreadyExistMessage}>
                        <span>
                          {t("label_enroll_already_reg")}
                          <span
                            className={styles.signInLink}
                            onClick={handleSignInPopupShow}
                          >
                            {" "}
                            {t("label_enroll_sign_in")}{" "}
                          </span>{" "}
                        </span>
                      </div>
                    </div>
                  )}
                  {wentWrong && (
                    <div className={styles.alreadyExistContainer}>
                      <TGIcon icon="error-icon" fillColor="" size="16" />
                      <div className={styles.alreadyExistMessage}>
                        <span>{t("label_something_went_wrong")}</span>
                      </div>
                    </div>
                  )}
                  {showSignIn && (
                    <LoginWidget
                      onHidePanel={handleSignInPopupClose}
                      loginName={() => {}}
                      handlePanelShow={handleSignInPopupShow}
                      isTabbed={true}
                    />
                  )}
                </div>
                <div className={styles.primaryEnrollContainer}>
                  <div className={styles.primaryEnroll}>
                    <TGSelection
                      isEnroll={true}
                      label={t("enroll_title")}
                      onChange={handleChange}
                      onSelect={(e, ob) => {
                        onSelect(e, ob);
                        setTitleState(e, ob);
                      }}
                      listData={[
                        { id: 1, listName: t("enroll_mr") },
                        { id: 2, listName: t("enroll_mrs") },
                        { id: 3, listName: t("enroll_ms") },
                        { id: 4, listName: t("enroll_mister") },
                        { id: 5, listName: t("enroll_miss") },
                        { id: 6, listName: t("enroll_title_prefer_not") },
                      ]}
                      name="title"
                      isLoading={false}
                      value={enrollmentData.title}
                      forceClose={forceClose}
                      isError={!!errors.title}
                      errorLabel={errors.title}
                      customClassName={styles.fieldWidth}
                      isReadOnly={true}
                    />
                  </div>
                  <div className={styles.primaryEnroll}>
                    <TGInput
                      isEnroll={true}
                      label={t("enroll_firstname")}
                      value={enrollmentData.firstName}
                      name="firstName"
                      onChange={handleChange}
                      isError={!!errors.firstName}
                      errorLabel={
                        !!errors.firstName &&
                        enrollmentData?.firstName?.length === 0
                          ? t("error_reset_first_name_required")
                          : errors.firstName
                      }
                      customClassName={styles.fieldContainer}
                      validation="name"
                      maxLength={40}
                    />
                    <TGInput
                      isEnroll={true}
                      label={t("enroll_lastName")}
                      value={enrollmentData.lastName}
                      name="lastName"
                      onChange={handleChange}
                      isError={!!errors.lastName}
                      errorLabel={
                        !!errors.lastName &&
                        enrollmentData?.lastName?.length === 0
                          ? t("error_reset_last_name_required")
                          : errors.lastName
                      }
                      customClassName={styles.fieldContainer}
                      validation="name"
                      maxLength={40}
                    />
                  </div>

                  <div className={styles.primaryEnroll}>
                    <TGDateDropdown
                      isEnroll={true}
                      isDesktop={true}
                      label={t("label_reset_dob")}
                      isError={!!errors.dob}
                      onDateChange={handleDateChange}
                      errorLabel={errors.dob}
                      savedDob={
                        enrollmentData.dob
                          ? enrollmentData.dob
                          : formattedDob != ""
                          ? formattedDob
                          : ""
                      }
                    />
                    <TGSelection
                      isEnroll={true}
                      label={t("enroll_gender")}
                      onChange={handleChange}
                      onSelect={(e, ob) => {
                        onSelect(e, ob);
                        setGenderState(e, ob);
                      }}
                      listData={[
                        { id: 1, listName: t("enroll_male") },
                        { id: 2, listName: t("enroll_female") },
                        // { id: 3, listName: t("enroll_Unspecified") },
                        { id: 3, listName: t("enroll_undisclosed") },
                      ]}
                      name="gender"
                      isLoading={false}
                      value={enrollmentData.gender}
                      forceClose={forceClose}
                      isError={!!errors.gender}
                      errorLabel={errors.gender}
                      customClassName={styles.fieldWidth}
                      isReadOnly={true}
                    />
                  </div>
                  <div className={styles.addressContainer}>
                    <TGInput
                      isEnroll={true}
                      label={t("enroll_address_line1")}
                      value={enrollmentData.addressLine1}
                      name="addressLine1"
                      onChange={handleChange}
                      isError={!!errors.addressLine1}
                      errorLabel={t("Address_line1_required")}
                      placeholder="Example: House No., Alley, Road"
                      validation="address"
                    />
                    <TGInput
                      isEnroll={true}
                      label={t("enroll_address_line2")}
                      value={enrollmentData.addressLine2}
                      name="addressLine2"
                      onChange={handleChange}
                      validation="address"
                    />
                  </div>
                  <div className={styles.countryContainer}>
                    <div className={styles.countryEnroll}>
                      <Form.Group style={{ flex: "1", padding: "0" }}>
                        <Form.Label className={styles.tgEnrollInputLabel}>
                          {t("enroll_country")}
                        </Form.Label>
                        <TGTypeahead
                          id="country"
                          labelKey={(option) => option.name}
                          options={countryData}
                          onChange={handleCountryChange}
                          selected={selectedCountry ? [selectedCountry] : []}
                          selectedValue={selectedCountry?.name}
                          isOpen={isToggleOpen === "country"}
                          setIsOpen={(id) => setIsToggleOpen(id)}
                          error={errors.country}
                          showImage={true}
                        />
                        {errors.country && (
                          <Form.Label className={styles.errorLabel}>
                            {t("enroll_country_required")}
                          </Form.Label>
                        )}
                      </Form.Group>
                    </div>
                    <div></div>
                  </div>
                  <div className={styles.primaryEnroll}>
                    <Form.Group style={{ flex: "1", padding: "0" }}>
                      <Form.Label className={styles.tgEnrollInputLabel}>
                        {t("enroll_city")}
                      </Form.Label>
                      <TGTypeahead
                        id="city"
                        labelKey={(option: any) => `${option.name}`}
                        options={[
                          ...filteredCities,
                          { name: "Other (Please specify)" },
                        ]}
                        onChange={handleCityChange}
                        selected={selectedCity ? [selectedCity] : []}
                        selectedValue={selectedCity}
                        isOpen={isToggleOpen === "city"}
                        setIsOpen={(id) => setIsToggleOpen(id)}
                        error={errors.city}
                        disabled={isDisabled}
                      />
                      {errors.city && (
                        <Form.Label className={styles.errorLabel}>
                          {t("enroll_city_required")}
                        </Form.Label>
                      )}
                    </Form.Group>
                    <TGInput
                      isEnroll={true}
                      label={t("label_other_city_province")}
                      value={enrollmentData.otherCity}
                      name="otherCity"
                      onChange={handleOtherCityChange}
                      isError={!!errors.otherCity}
                      errorLabel={
                        selectedCity === "Other (Please specify)" &&
                        otherCity.trim() === ""
                          ? t("error_enroll_other_city_province")
                          : " "
                      }
                      customClassName={styles.setFlex}
                      validation="address"
                      maxLength={40}
                      disables={selectedCity?.name != "Other (Please specify)"}
                    />
                  </div>
                  <div className={styles.primaryEnroll}>
                    <Form.Group style={{ flex: "1", padding: "0" }}>
                      <Form.Label className={styles.tgEnrollInputLabel}>
                        {t("enroll_state")}
                      </Form.Label>
                      <TGTypeahead
                        id="state"
                        labelKey={(option) => `${option.name}`}
                        options={filteredStates}
                        onChange={handleStateChange}
                        selected={selectedState ? [selectedState] : []}
                        selectedValue={selectedState?.name}
                        isOpen={isToggleOpen === "state"}
                        setIsOpen={(id) => setIsToggleOpen(id)}
                        error={errors.state}
                        disabled={isDisabledState}
                      />
                    </Form.Group>
                    <TGInput
                      isEnroll={true}
                      label={t("enroll_postal")}
                      value={enrollmentData.postalCode}
                      name="postalCode"
                      onChange={handleChange}
                      isError={!!errors.postalCode}
                      errorLabel={t("enroll_postal_required")}
                      disables={isDisabled}
                      customClassName={styles.setFlex}
                      validation="postal"
                    />
                  </div>
                  <div className={styles.contactContainer}>
                    <div className={styles.contactEnroll}>
                      <Form.Label className={styles.tgSelectLabel}>
                        {t("profile_view_contact")}
                      </Form.Label>
                      <Form.Group>
                        <InputGroup>
                          <TGTypeahead
                            className={styles.wrapper}
                            id="countryCode"
                            labelKey={(option: any) => `${option.dial_code}`}
                            isOpen={isToggleOpen === "countryCode"}
                            setIsOpen={(id) => setIsToggleOpen(id)}
                            error={errors.country}
                            showImage={true}
                            options={countryData}
                            onChange={handleCountryCodeChange}
                            onInputChange={(e) => setCountryCodeValue(e)}
                            selected={
                              selectedCountryCode ? [selectedCountryCode] : []
                            }
                          />
                          <Form.Control
                            className={`${styles["tg-input-box"]} ${
                              styles.borderContact
                            }  ${
                              !!errors.contactNumber && styles["tg-input-error"]
                            }`}
                            onChange={handleChange}
                            type={"text"}
                            name="contactNumber"
                            maxLength={24}
                            value={enrollmentData.contactNumber}
                            placeholder="00-000-0000"
                          />
                          {!!errors.contactNumber && (
                            <div className={styles["tg-input-error-icon"]}>
                              <TGIcon
                                icon="error-icon"
                                fillColor=""
                                size="16"
                              />
                            </div>
                          )}
                        </InputGroup>
                        {errors.countryCode && errors.contactNumber ? (
                          <span>
                            {errors.contactNumber && (
                              <Form.Label className={styles.errorLabel}>
                                {t("profile_view_contact_err")}
                              </Form.Label>
                            )}
                          </span>
                        ) : (
                          <span className={styles["error-container"]}>
                            {errors.countryCode && (
                              <Form.Label className={styles.errorLabel}>
                                {t("label_country_code_error")}
                              </Form.Label>
                            )}
                            {errors.contactNumber && (
                              <Form.Label className={styles.errorLabel}>
                                {t("profile_view_contact_err")}
                              </Form.Label>
                            )}
                          </span>
                        )}
                      </Form.Group>
                    </div>
                    <div></div>
                  </div>

                  <div className={styles.primaryEnroll}>
                    <TGInput
                      isEnroll={true}
                      label={t("label_reset_email")}
                      value={enrollmentData.email}
                      name="email"
                      onChange={handleChange}
                      isError={!!errors.email}
                      errorLabel={errors.email}
                      placeholder="Example: THAI@gmail.com"
                    />
                    <TGInput
                      isEnroll={true}
                      label={t("enroll_confirm_email")}
                      value={enrollmentData.confirmEmail}
                      name="confirmEmail"
                      onChange={handleChange}
                      isError={!!errors.confirmEmail}
                      errorLabel={errors.confirmEmail}
                      placeholder="Example: THAI@gmail.com"
                    />
                  </div>
                  <div className={styles.passwordContainer}></div>
                  <div className={styles.primaryEnroll}>
                    <TGInput
                      isEnroll={true}
                      label={t("enroll_create_password")}
                      value={enrollmentData.createPassword}
                      name="createPassword"
                      onChange={handleChange}
                      isError={!!errors.createPassword}
                      errorLabel={errors.createPassword}
                      type="password"
                      validation="noSpecialChar"
                    />
                    <TGInput
                      isEnroll={true}
                      label={t("enroll_confirm_password")}
                      value={enrollmentData.confirmPassword}
                      name="confirmPassword"
                      onChange={handleChange}
                      isError={!!errors.confirmPassword}
                      errorLabel={errors.confirmPassword}
                      type="password"
                      validation="noSpecialChar"
                    />
                  </div>
                  <div className={styles.passwordCriteria}>
                    <div className={styles.passwordCriteriaInner}>
                      <span className={styles.PasswordContainText}>
                        {t("label_password_rule_text")}
                      </span>
                      <div className={styles.criterias}>
                        <span className={styles.criteria}>
                          {criteria.minLength ? (
                            <span className={styles.checkValid}>
                              <TGIcon
                                icon="check-green-icon"
                                size=" "
                                fillColor=""
                              />
                            </span>
                          ) : (
                            <span className={styles.check}>
                              <TGIcon icon="check-icon" size=" " fillColor="" />
                            </span>
                          )}
                          <div className={styles.criteriaText}>
                            {t("label_password_length_rule")}
                          </div>
                        </span>
                        <span className={styles.criteria}>
                          {!criteria.hasSpecialChar ? (
                            <span className={styles.checkValid}>
                              <TGIcon
                                icon="check-green-icon"
                                size=" "
                                fillColor=""
                              />
                            </span>
                          ) : (
                            <span className={styles.check}>
                              <TGIcon icon="check-icon" size=" " fillColor="" />
                            </span>
                          )}
                          <div className={styles.criteriaText}>
                            {t("enroll_special_char")}
                          </div>
                        </span>
                        <span className={styles.criteria}>
                          {criteria.hasNumber ? (
                            <span className={styles.checkValid}>
                              <TGIcon
                                icon="check-green-icon"
                                size=" "
                                fillColor=""
                              />
                            </span>
                          ) : (
                            <span className={styles.check}>
                              <TGIcon icon="check-icon" size=" " fillColor="" />
                            </span>
                          )}

                          <div className={styles.criteriaText}>
                            {t("label_password_digit_rule")}
                          </div>
                        </span>
                        <span className={styles.criteria}>
                          {criteria.hasAlphabet ? (
                            <span className={styles.checkValid}>
                              <TGIcon
                                icon="check-green-icon"
                                size=" "
                                fillColor=""
                              />
                            </span>
                          ) : (
                            <span className={styles.check}>
                              <TGIcon icon="check-icon" size=" " fillColor="" />
                            </span>
                          )}

                          <div className={styles.criteriaText}>
                            {t("label_password_alphabet_rule")}
                          </div>
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div className={styles.referingEnroll}>
                  <TGInput
                    isEnroll={true}
                    label={t("enroll_referal")}
                    value={enrollmentData.referringMemberID}
                    name="referringMemberID"
                    onChange={handleChange}
                    customClassName={styles.setFlexGrow}
                    placeholder="Example: ZZ9999"
                    validation="rop"
                  />
                  <div className={styles.referringText}>
                    {t("label_enroll_refereal_Desc")}
                  </div>
                </div>
                <div className={styles.preferences}>
                  <div className={styles.heading}>
                    {t("label_inflight_preference")}
                  </div>
                  <div className={styles.description}>
                    {t("label_inflight_preference_desc")}
                  </div>
                  <div className={styles.primaryEnroll}>
                    <TGSelection
                      isEnroll={true}
                      customClassName={styles.fieldWidth}
                      savedValue={
                        location.state
                          ? location.state.enrollmentData?.seatPreferenceValue
                          : ""
                      }
                      label={t("label_seat_preference")}
                      onChange={handleChange}
                      onSelect={(e, ob) => {
                        onSelect(e, ob);
                        setSeatState(e, ob);
                      }}
                      listData={[
                        { id: 1, listName: "Aisle", listAbbr: "NSSA" },
                        { id: 2, listName: "Window", listAbbr: "NSSW" },
                        { id: 3, listName: "No Preference", listAbbr: "NNN" },
                      ]}
                      name="seatPreference"
                      isLoading={false}
                      forceClose={forceClose}
                      isReadOnly={true}
                      displayDefaultValue={true}
                      defaultValue={defaultPreference.seat}
                      isReadOnly={true}
                    />
                    <TGSelection
                      isEnroll={true}
                      customClassName={styles.fieldWidth}
                      label={t("label_meal_preferences")}
                      onChange={handleChange}
                      savedValue={
                        location.state
                          ? location.state.enrollmentData?.mealPreferenceValue
                          : ""
                      }
                      onSelect={(e, ob) => {
                        onSelect(e, ob);
                        setMealState(e, ob);
                      }}
                      listData={mealData}
                      name="mealPreference"
                      isLoading={false}
                      forceClose={forceClose}
                      displayDefaultValue={true}
                      defaultValue={defaultPreference.meal}
                      isReadOnly={true}
                    />
                  </div>
                  <div className={styles.languageEnroll}>
                    <TGSelection
                      isEnroll={true}
                      savedValue={
                        location.state
                          ? location.state.enrollmentData
                              ?.languagePreferenceValue
                          : ""
                      }
                      customClassName={styles.fieldContainer}
                      label={t("label_language_preferences")}
                      onChange={handleChange}
                      onSelect={(e, ob) => {
                        onSelect(e, ob);
                        setLanguageState(e, ob);
                      }}
                      listData={[
                        { id: 1, listName: "English", listAbbr: "E" },
                        { id: 2, listName: "Thai", listAbbr: "T" },
                      ]}
                      name="languagePreference"
                      isLoading={false}
                      forceClose={forceClose}
                      displayDefaultValue={true}
                      defaultValue={defaultPreference.language}
                      isReadOnly={true}
                    />
                  </div>
                </div>
                <div className={styles.marketingContainer}>
                  <div className={styles.referingEnroll}>
                    <div className={styles.heading}>
                      {t("label_enroll_marketing_heading")}
                    </div>
                    <div className={styles.description}>
                      {t("label_enroll_marketing_desc")}
                    </div>
                  </div>
                  <div className={styles.agreeContainer}>
                    <TGCheckBox
                      label={t("label_enroll_primary_checkbox")}
                      onChange={checkedPrimaryChange}
                      checked={isPrimaryChecked}
                      customLabel="checkLabel"
                      className="checkLabel"
                    />
                    {/* {!isPreferenceDisabled && (
                      <div className={styles.preferredNewsDropDownContainer}>
                        <Form.Group style={{ width: "50%" }}>
                          <Form.Label
                            className={styles.preferredNewsDropDownLabel}
                          >
                            {t("label_preferred_news_rop")}
                          </Form.Label>
                          <Typeahead
                            id="preferredCountry"
                            labelKey={(option: any) => `${option.country}`}
                            options={preferenceFromResponse}
                            onChange={handlePreferredCountryChange}
                            placeholder={
                              selectedCountryValue !== null
                                ? selectedCountryValue?.country?.toUpperCase()
                                : ""
                            }
                            selected={
                              selectPreferenceCountry
                                ? [selectPreferenceCountry]
                                : []
                            }
                            disabled={isPreferenceDisabled ? true : false}
                            renderMenuItemChildren={(option: any) => (
                              <div>
                                <span>{option.country?.toUpperCase()}</span>
                              </div>
                            )}
                            renderInput={({
                              inputRef,
                              referenceElementRef,
                              ...inputProps
                            }) => (
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  position: "relative",
                                }}
                              >
                                {
                                  <>
                                    {selectPreferenceCountry && (
                                      <div
                                        style={{
                                          position: "absolute",
                                          left: "10px",
                                          pointerEvents: "none",
                                        }}
                                      ></div>
                                    )}
                                    <Form.Control
                                      {...inputProps}
                                      onKeyDown={handleKeyDown}
                                      ref={(node: HTMLInputElement) => {
                                        inputRef(node);
                                        referenceElementRef(node);
                                      }}
                                      name="country"
                                      value={
                                        selectPreferenceCountry
                                          ? selectPreferenceCountry.country?.toUpperCase()
                                          : (inputProps.value?.toUpperCase() as string)
                                      }
                                      style={{
                                        flex: 1,
                                        paddingLeft: "12px",
                                      }}
                                      className={`${styles["tg-select-box"]}`}
                                    ></Form.Control>
                                    {errors.preference &&
                                      !isPreferenceDisabled && (
                                        <div
                                          className={styles.errorIcon}
                                          style={{
                                            position: "absolute",
                                            right: "30px",
                                            pointerEvents: "none",
                                            color: "#000",
                                            fontSize: "16px",
                                          }}
                                        >
                                          <TGIcon
                                            icon="error-icon"
                                            fillColor=""
                                            size="16"
                                          />
                                        </div>
                                      )}
                                    <div
                                      style={{
                                        position: "absolute",
                                        right: "10px",
                                        pointerEvents: "none",
                                        color: "#000",
                                        fontSize: "16px",
                                      }}
                                    >
                                      <TGIcon
                                        icon="chevron-down-icon"
                                        fillColor=""
                                        size="16"
                                      />
                                    </div>
                                  </>
                                }
                              </div>
                            )}
                          ></Typeahead>
                          {errors.preference && !isPreferenceDisabled && (
                            <Form.Label className={styles.errorLabel}>
                              {t("label_preferred_news_rop_err_msg")}
                            </Form.Label>
                          )}
                        </Form.Group>
                      </div>
                    )} */}
                    {/* {
                      isPrimaryChecked &&(
                        <div className={styles.marketingInput}>
                            <TGSelection
                            isEnroll={true}
                        label={t('label_preferred_news_rop')}
                        onChange={handleChange}
                        onSelect={(e, ob) => {
                          onSelect(e, ob);
                          setTitleState(e, ob);
                        }}
                        listData={[
                          { id: 1, listName: t('enroll_mr') },
                          { id: 2, listName: t('enroll_mrs') },
                          { id: 3, listName: t('enroll_ms') },
                          { id: 4, listName: t('enroll_mister') },
                          { id: 5, listName: t('enroll_miss') },
                        ]}
                        name="title"
                        isLoading={false}
                        value={enrollmentData.title}
                        forceClose={forceClose}
                        isError={!!errors.title}
                        errorLabel={errors.title}
                        customClassName={styles.fieldWidth}
                      />
                          </div>
                      
                      )
                    } */}
                    <TGCheckBox
                      label={t("label_enroll_secondary_checkbox")}
                      onChange={checkedSecondaryChange}
                      checked={isSecondaryChecked}
                      className="checkLabel"
                      customLabel="checkLabel"
                    />
                  </div>
                </div>

                {isLessThanSixteen &&
                  (isPrimaryChecked || isSecondaryChecked) && (
                    <div className={styles.parentConsent}>
                      <div className={styles.parentContainer}>
                        <span className={styles.parentTextHeading}>
                          {t("label_enrolment_consent_parent_info_title")}{" "}
                        </span>
                        <p className={styles.parentDesc}>
                          {t("label_enrolment_crm_consent_parent_description")}
                        </p>
                      </div>
                      <div className={styles.inputParentContainer}>
                        <div className={styles.inputParentSubContainer}>
                          <TGInput
                            isEnroll={true}
                            label={t("label_reset_first_name")}
                            value={enrollmentData.consentParentFirstName}
                            name="consentParentFirstName"
                            onChange={handleChange}
                            isError={!!errors.consentParentFirstName}
                            errorLabel={
                              !!errors.consentParentFirstName &&
                              enrollmentData?.consentParentFirstName?.length ===
                                0
                                ? t("error_parent_middle_name_required")
                                : errors.consentParentFirstName
                            }
                            customClassName={styles.fieldContainer}
                            validation="name"
                            maxLength={40}
                          />
                          <TGInput
                            isEnroll={true}
                            label={t("label_manage_last_name")}
                            value={enrollmentData.consentParentLastName}
                            name="consentParentLastName"
                            onChange={handleChange}
                            isError={!!errors.consentParentLastName}
                            errorLabel={
                              !!errors.consentParentLastName &&
                              enrollmentData?.consentParentLastName?.length ===
                                0
                                ? t("error_reset_last_name_required")
                                : errors.consentParentLastName
                            }
                            customClassName={styles.fieldContainer}
                            validation="name"
                            maxLength={40}
                          />
                        </div>
                      </div>
                    </div>
                  )}

                <div className={styles.stroke}>
                  <TGIcon icon="stroke" size=" " fillColor="" />
                </div>
                <div className={styles.checkSignup}>
                  <TGCheckBox
                    isHtmlContent={true}
                    htmlContent={termConditionData.termConditiontext}
                    onChange={checkedChange}
                    checkref={termConditionRef}
                    checked={isChecked}
                    className="checkLabel"
                    customLabel="checkLabel"
                  />
                  {errors.termsConditions && (
                    <div className={styles.termsConditionsError}>
                      <TGIcon icon="error-icon" fillColor="" size="16" />
                      <div className={styles.termConditionText}>
                        {t("enroll_terms_condition")}
                      </div>
                    </div>
                  )}
                </div>
                <div className={styles.signupButton}>
                  <TGButtonVariants
                    label={
                      RopSIgnupResponse.isLoading
                        ? ""
                        : t("button_reset_continue")
                    }
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    borderRadius="30px"
                    boxShadow="0px 1px 2px 0px rgba(16, 24, 40, 0.05)"
                    bgColor="#684B9B"
                    padding="16px"
                    textColor="#FFFFFF"
                    fontFamily="Inter"
                    fontSize="16px"
                    fontStyle="normal"
                    fontWeight="700"
                    lineHeight="160%"
                    borderDisabled="0.791px solid #E3D4FE"
                    bgColorDisabled="#E3D4FE"
                    boxShadowDisabled="0px 1px 2px 0px rgba(16, 24, 40, 0.05)"
                    bgColorHover="#684b9b"
                    borderHover="0.791px solid #E3D4FE"
                    boxSHadowHover="0px 1px 2px 0px rgba(16, 24, 40, 0.05)"
                    height="50px"
                    width="25%"
                    onClick={handleSubmitEnroll}
                  >
                    {RopSIgnupResponse.isLoading ? (
                      <>
                        <Spinner
                          animation="grow"
                          variant="light"
                          style={{
                            width: "10px",
                            height: "10px",
                            margin: "5px",
                          }}
                        />
                        <Spinner
                          animation="grow"
                          variant="light"
                          style={{
                            width: "10px",
                            height: "10px",
                            margin: "5px",
                          }}
                        />
                        <Spinner
                          animation="grow"
                          variant="light"
                          style={{
                            width: "10px",
                            height: "10px",
                            margin: "5px",
                          }}
                        />
                      </>
                    ) : null}
                  </TGButtonVariants>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      {!isDesktopView && (
        <div className={styles.enrollMobileContainer}>
          <div className={styles.enrollMobileInnerContainer}>
            <div className={styles.headingContainer}>
              <div className={styles.title}>{t("enroll_personal_Info")}</div>
              <div className={styles.description}>
                {t("enroll_rop_description")}{" "}{t("enroll_age_validation_2_yrs")}
              </div>
              {ropAlreadyExist && (
                <div className={styles.alreadyExistContainer}>
                  <TGIcon icon="error-icon" fillColor="" size="16" />
                  <div className={styles.alreadyExistMessage}>
                    <span>
                      {t("label_enroll_already_reg")}
                      <span
                        className={styles.signInLink}
                        onClick={handleSignInPopupShow}
                      >
                        {" "}
                        {t("label_enroll_sign_in")}{" "}
                      </span>{" "}
                    </span>
                  </div>
                </div>
              )}
              {wentWrong && (
                <div className={styles.alreadyExistContainer}>
                  <TGIcon icon="error-icon" fillColor="" size="16" />
                  <div className={styles.alreadyExistMessage}>
                    <span>{t("label_something_went_wrong")}</span>
                  </div>
                </div>
              )}
              {showSignIn && (
                <LoginWidget
                  onHidePanel={handleSignInPopupClose}
                  loginName={() => {}}
                  handlePanelShow={handleSignInPopupShow}
                  isTabbed={true}
                />
              )}
            </div>
            <div className={styles.personalInfo}>
              <TGSelection
                isEnroll={true}
                label={t("enroll_title")}
                onChange={handleChange}
                onSelect={(e, ob) => {
                  onSelect(e, ob);
                  setTitleState(e, ob);
                }}
                listData={[
                  { id: 1, listName: t("enroll_mr") },
                  { id: 2, listName: t("enroll_mrs") },
                  { id: 3, listName: t("enroll_ms") },
                  { id: 4, listName: t("enroll_mister") },
                  { id: 5, listName: t("enroll_miss") },
                ]}
                name="title"
                isLoading={false}
                value={enrollmentData.title}
                forceClose={forceClose}
                isError={!!errors.title}
                errorLabel={errors.title}
                isReadOnly={true}
              />
              <TGInput
                isEnroll={true}
                label={t("enroll_firstname")}
                value={enrollmentData.firstName}
                name="firstName"
                onChange={handleChange}
                isError={!!errors.firstName}
                errorLabel={
                  !!errors.firstName && enrollmentData?.firstName?.length === 0
                    ? t("error_reset_first_name_required")
                    : errors.firstName
                }
                customClassName={styles.fieldContainer}
                validation="name"
                maxLength={40}
              />
              <TGInput
                isEnroll={true}
                label={t("enroll_lastName")}
                value={enrollmentData.lastName}
                name="lastName"
                onChange={handleChange}
                isError={!!errors.lastName}
                errorLabel={
                  !!errors.lastName && enrollmentData?.lastName?.length === 0
                    ? t("error_reset_last_name_required")
                    : errors.lastName
                }
                customClassName={styles.fieldContainer}
                validation="name"
                maxLength={40}
              />
              <TGDateDropdown
                isEnroll={true}
                isDesktop={false}
                label={t("label_reset_dob")}
                isError={!!errors.dob}
                onDateChange={handleDateChange}
                errorLabel={errors.dob}
                savedDob={
                  enrollmentData.dob
                    ? enrollmentData.dob
                    : formattedDob != ""
                    ? formattedDob
                    : ""
                }
              />
              <TGSelection
                isEnroll={true}
                label={t("enroll_gender")}
                onChange={handleChange}
                onSelect={(e, ob) => {
                  onSelect(e, ob);
                  setGenderState(e, ob);
                }}
                listData={[
                  { id: 1, listName: t("enroll_male") },
                  { id: 2, listName: t("enroll_female") },
                  { id: 3, listName: t("enroll_undisclosed") },
                ]}
                name="gender"
                isLoading={false}
                value={enrollmentData.gender}
                forceClose={forceClose}
                isError={!!errors.gender}
                errorLabel={errors.gender}
                isReadOnly={true}
              />
              <TGInput
                isEnroll={true}
                label={t("enroll_address_line1")}
                value={enrollmentData.addressLine1}
                name="addressLine1"
                onChange={handleChange}
                isError={!!errors.addressLine1}
                errorLabel={t("Address_line1_required")}
                placeholder="Example: House No., Alley, Road"
                validation="address"
              />
              <TGInput
                isEnroll={true}
                label={t("enroll_address_line2")}
                value={enrollmentData.addressLine2}
                name="addressLine2"
                onChange={handleChange}
                validation="address"
              />
              <Form.Group>
                <Form.Label className={styles.tgEnrollInputLabel}>
                  {t("enroll_country")}
                </Form.Label>
                <TGTypeahead
                  id="country"
                  labelKey={(option) => option.name}
                  options={countryData}
                  onChange={handleCountryChange}
                  selected={selectedCountry ? [selectedCountry] : []}
                  selectedValue={selectedCountry?.name}
                  isOpen={isToggleOpen === "country"}
                  setIsOpen={(id) => setIsToggleOpen(id)}
                  error={errors.country}
                  showImage={true}
                />
                {errors.country && (
                  <Form.Label className={styles.errorLabel}>
                    {t("enroll_country_required")}
                  </Form.Label>
                )}
              </Form.Group>
              <Form.Group>
                <Form.Label className={styles.tgEnrollInputLabel}>
                  {t("enroll_city")}
                </Form.Label>
                <TGTypeahead
                  id="city"
                  labelKey={(option: any) => `${option.name}`}
                  options={[
                    ...filteredCities,
                    { name: "Other (Please specify)" },
                  ]}
                  onChange={handleCityChange}
                  selected={selectedCity ? [selectedCity] : []}
                  selectedValue={selectedCity}
                  isOpen={isToggleOpen === "city"}
                  setIsOpen={(id) => setIsToggleOpen(id)}
                  error={errors.city}
                  disabled={isDisabled}
                />
                {errors.city && (
                  <Form.Label className={styles.errorLabel}>
                    {t("enroll_city_required")}
                  </Form.Label>
                )}
              </Form.Group>
              <Form.Group>
                <TGInput
                  isEnroll={true}
                  label={t("label_other_city_province")}
                  value={enrollmentData.otherCity}
                  name="otherCity"
                  onChange={handleOtherCityChange}
                  isError={!!errors.otherCity}
                  errorLabel={
                    selectedCity === "Other (Please specify)" &&
                    otherCity.trim() === ""
                      ? t("error_enroll_other_city_province")
                      : " "
                  }
                  customClassName={styles.setFlex}
                  validation="address"
                  maxLength={40}
                  disables={!(selectedCity === "Other (Please specify)")}
                />

                <Form.Label className={styles.tgEnrollInputLabel}>
                  {t("enroll_state")}
                </Form.Label>
                <TGTypeahead
                  id="state"
                  labelKey={(option) => `${option.name}`}
                  options={filteredStates}
                  onChange={handleStateChange}
                  selected={selectedState ? [selectedState] : []}
                  selectedValue={selectedState?.name}
                  isOpen={isToggleOpen === "state"}
                  setIsOpen={(id) => setIsToggleOpen(id)}
                  error={errors.state}
                  disabled={isDisabledState}
                />
              </Form.Group>
              <TGInput
                isEnroll={true}
                label={t("enroll_postal")}
                value={enrollmentData.postalCode}
                name="postalCode"
                onChange={handleChange}
                isError={!!errors.postalCode}
                errorLabel={t("enroll_postal_required")}
                disables={isDisabled}
                validation="postal"
              />

              <div className={styles.contactContainerMobile}>
                <div className={styles.contactEnroll}>
                  <Form.Label className={styles.tgSelectLabel}>
                    {t("profile_view_contact")}
                  </Form.Label>
                  <Form.Group>
                    <InputGroup style={{ width: "90vw" }}>
                      <TGTypeahead
                        className={styles.wrapper}
                        id="countryCode"
                        labelKey={(option: any) =>
                          `${option.dial_code}${option.name}${option.code}`
                        }
                        options={countryData}
                        onChange={handleCountryCodeChange}
                        onInputChange={(e) => setCountryCodeValue(e)}
                        selected={
                          selectedCountryCode ? [selectedCountryCode] : []
                        }
                        isOpen={isToggleOpen === "countryCode"}
                        setIsOpen={(id) => setIsToggleOpen(id)}
                        showImage={true}
                      />
                      <Form.Control
                        className={`${styles["tg-input-box"]} ${
                          styles.borderContact
                        }  ${
                          !!errors.contactNumber && styles["tg-input-error"]
                        }`}
                        onChange={handleChange}
                        type={"text"}
                        maxLength={24}
                        name="contactNumber"
                        value={enrollmentData?.contactNumber}
                        placeholder="00-000-0000"
                      />
                      {!!errors.contactNumber && (
                        <div className={styles["tg-input-error-icon"]}>
                          <TGIcon icon="error-icon" fillColor="" size="16" />
                        </div>
                      )}
                    </InputGroup>
                    {errors.countryCode && errors.contactNumber ? (
                      <span>
                        {errors.contactNumber && (
                          <Form.Label className={styles.errorLabel}>
                            {t("profile_view_contact_err")}
                          </Form.Label>
                        )}
                      </span>
                    ) : (
                      <span className={styles["error-container"]}>
                        {errors.countryCode && (
                          <Form.Label className={styles.errorLabel}>
                            {t("label_country_code_error")}
                          </Form.Label>
                        )}
                        {errors.contactNumber && (
                          <Form.Label className={styles.errorLabel}>
                            {t("profile_view_contact_err")}
                          </Form.Label>
                        )}
                      </span>
                    )}
                  </Form.Group>
                </div>
              </div>

              <TGInput
                isEnroll={true}
                label={t("label_reset_email")}
                value={enrollmentData.email}
                name="email"
                onChange={handleChange}
                isError={!!errors.email}
                errorLabel={errors.email}
                placeholder="Example: THAI@gmail.com"
                validation="language"
              />
              <TGInput
                isEnroll={true}
                label={t("enroll_confirm_email")}
                value={enrollmentData.confirmEmail}
                name="confirmEmail"
                onChange={handleChange}
                isError={!!errors.confirmEmail}
                errorLabel={errors.confirmEmail}
                placeholder="Example: THAI@gmail.com"
                validation="language"
              />
              <TGInput
                isEnroll={true}
                label={t("enroll_create_password")}
                value={enrollmentData.createPassword}
                name="createPassword"
                onChange={handleChange}
                isError={!!errors.createPassword}
                errorLabel={errors.createPassword}
                type="password"
                validation="noSpecialChar"
              />

              <div className={styles.passwordCriteria}>
                <div className={styles.passwordCriteriaInner}>
                  <span className={styles.PasswordContainText}>
                    {t("label_password_rule_text")}
                  </span>
                  <div className={styles.criterias}>
                    <span className={styles.criteria}>
                      {criteria.minLength ? (
                        <span className={styles.checkValid}>
                          <TGIcon
                            icon="check-green-icon"
                            size=" "
                            fillColor=""
                          />
                        </span>
                      ) : (
                        <span className={styles.check}>
                          <TGIcon icon="check-icon" size=" " fillColor="" />
                        </span>
                      )}
                      <div className={styles.criteriaText}>
                        {t("label_password_length_rule")}
                      </div>
                    </span>
                    <span className={styles.criteria}>
                      {!criteria.hasSpecialChar ? (
                        <span className={styles.checkValid}>
                          <TGIcon
                            icon="check-green-icon"
                            size=" "
                            fillColor=""
                          />
                        </span>
                      ) : (
                        <span className={styles.check}>
                          <TGIcon icon="check-icon" size=" " fillColor="" />
                        </span>
                      )}
                      <div className={styles.criteriaText}>
                        {t("enroll_special_char")}
                      </div>
                    </span>
                    <span className={styles.criteria}>
                      {criteria.hasNumber ? (
                        <span className={styles.checkValid}>
                          <TGIcon
                            icon="check-green-icon"
                            size=" "
                            fillColor=""
                          />
                        </span>
                      ) : (
                        <span className={styles.check}>
                          <TGIcon icon="check-icon" size=" " fillColor="" />
                        </span>
                      )}

                      <div className={styles.criteriaText}>
                        {t("label_password_digit_rule")}
                      </div>
                    </span>
                    <span className={styles.criteria}>
                      {criteria.hasAlphabet ? (
                        <span className={styles.checkValid}>
                          <TGIcon
                            icon="check-green-icon"
                            size=" "
                            fillColor=""
                          />
                        </span>
                      ) : (
                        <span className={styles.check}>
                          <TGIcon icon="check-icon" size=" " fillColor="" />
                        </span>
                      )}

                      <div className={styles.criteriaText}>
                        {t("label_password_alphabet_rule")}
                      </div>
                    </span>
                  </div>
                </div>
              </div>
              <TGInput
                isEnroll={true}
                label={t("enroll_confirm_password")}
                value={enrollmentData.confirmPassword}
                name="confirmPassword"
                onChange={handleChange}
                isError={!!errors.confirmPassword}
                errorLabel={errors.confirmPassword}
                type="password"
                validation="noSpecialChar"
              />
              <div className={styles.referingEnroll}>
                <TGInput
                  isEnroll={true}
                  label={t("enroll_referal")}
                  value={enrollmentData.referringMemberID}
                  name="referringMemberID"
                  onChange={handleChange}
                  placeholder="Example: ZZ9999"
                  validation="rop"
                />
                <div className={styles.mobileReferingText}>
                  {t("label_enroll_refereal_Desc")}
                </div>
              </div>
              <div className={styles.heading}>
                {t("label_inflight_preference")}
              </div>
              <div className={styles.description}>
                {t("label_inflight_preference_desc")}
              </div>
              <TGSelection
                isEnroll={true}
                label={t("label_seat_preference")}
                onChange={handleChange}
                onSelect={(e, ob) => {
                  onSelect(e, ob);
                  setSeatState(e, ob);
                }}
                listData={[
                  { id: 1, listName: "Aisle", listAbbr: "NSSA" },
                  { id: 2, listName: "Window", listAbbr: "NSSW" },
                  { id: 3, listName: "No Preference", listAbbr: "NNN" },
                ]}
                name="seatPreference"
                isLoading={false}
                forceClose={forceClose}
                displayDefaultValue={true}
                defaultValue="No Preference"
                isReadOnly={true}
              />
              <TGSelection
                isEnroll={true}
                label={t("label_meal_preferences")}
                onChange={handleChange}
                onSelect={(e, ob) => {
                  onSelect(e, ob);
                  setMealState(e, ob);
                }}
                listData={mealData}
                name="mealPreference"
                isLoading={false}
                forceClose={forceClose}
                displayDefaultValue={true}
                isReadOnly={true}
                defaultValue="No Preference"
                isReadOnly={true}
              />
              <TGSelection
                isEnroll={true}
                customClassName={styles.fieldContainer}
                label={t("label_language_preferences")}
                onChange={handleChange}
                onSelect={(e, ob) => {
                  onSelect(e, ob);
                  setLanguageState(e, ob);
                }}
                listData={[
                  { id: 1, listName: "English", listAbbr: "E" },
                  { id: 2, listName: "Thai", listAbbr: "T" },
                ]}
                name="languagePreference"
                isLoading={false}
                forceClose={forceClose}
                displayDefaultValue={true}
                isReadOnly={true}
                defaultValue="English"
              />
              <div className={styles.marketingContainer}>
                <div className={styles.referingEnroll}>
                  <div className={styles.heading}>
                    {t("label_enroll_marketing_heading")}
                  </div>
                  <div className={styles.description}>
                    {t("label_enroll_marketing_desc")}
                  </div>
                </div>
                <div className={styles.agreeContainer}>
                  <TGCheckBox
                    label={t("label_enroll_primary_checkbox")}
                    onChange={checkedPrimaryChange}
                    checked={isPrimaryChecked}
                    customLabel="checkLabel"
                    className="checkLabel"
                  />
                  {/* {!isPreferenceDisabled && (
                    <div className={styles.preferredNewsDropDownContainer}>
                      <Form.Group style={{ flex: "1", padding: "0" }}>
                        <Form.Label
                          className={styles.preferredNewsDropDownLabel}
                        >
                          {t("label_preferred_news_rop")}
                        </Form.Label>
                        <Typeahead
                          id="preferredCountry"
                          labelKey={(option: any) => `${option.country}`}
                          options={preferenceFromResponse}
                          onChange={handlePreferredCountryChange}
                          placeholder={
                            selectedCountryValue !== null
                              ? selectedCountryValue?.country?.toUpperCase()
                              : ""
                          }
                          selected={
                            selectPreferenceCountry
                              ? [selectPreferenceCountry]
                              : []
                          }
                          disabled={isPreferenceDisabled ? true : false}
                          renderMenuItemChildren={(option: any) => (
                            <div>
                              <span>{option.country}</span>
                            </div>
                          )}
                          renderInput={({
                            inputRef,
                            referenceElementRef,
                            ...inputProps
                          }) => (
                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                                position: "relative",
                              }}
                            >
                              {
                                <>
                                  {selectPreferenceCountry && (
                                    <div
                                      style={{
                                        position: "absolute",
                                        left: "10px",
                                        pointerEvents: "none",
                                      }}
                                    ></div>
                                  )}
                                  <Form.Control
                                    {...inputProps}
                                    ref={(node: HTMLInputElement) => {
                                      inputRef(node);
                                      referenceElementRef(node);
                                    }}
                                    name="country"
                                    onKeyDown={handleKeyDown}
                                    value={
                                      selectPreferenceCountry
                                        ? selectPreferenceCountry.country?.toUpperCase()
                                        : (inputProps.value?.toUpperCase() as string)
                                    }
                                    style={{
                                      flex: 1,
                                      paddingLeft: "12px",
                                    }}
                                    className={`${styles["tg-select-box"]}`}
                                  ></Form.Control>
                                  {errors.preference &&
                                    !isPreferenceDisabled && (
                                      <div
                                        className={styles.errorIcon}
                                        style={{
                                          position: "absolute",
                                          right: "30px",
                                          pointerEvents: "none",
                                          color: "#000",
                                          fontSize: "16px",
                                        }}
                                      >
                                        <TGIcon
                                          icon="error-icon"
                                          fillColor=""
                                          size="16"
                                        />
                                      </div>
                                    )}
                                  <div
                                    style={{
                                      position: "absolute",
                                      right: "10px",
                                      pointerEvents: "none",
                                      color: "#000",
                                      fontSize: "16px",
                                    }}
                                  >
                                    <TGIcon
                                      icon="chevron-down-icon"
                                      fillColor=""
                                      size="16"
                                    />
                                  </div>
                                </>
                              }
                            </div>
                          )}
                        ></Typeahead>
                        {errors.preference && !isPreferenceDisabled && (
                          <Form.Label className={styles.errorLabel}>
                            {t("label_preferred_news_rop_err_msg")}
                          </Form.Label>
                        )}
                      </Form.Group>
                    </div>
                  )} */}
                  <TGCheckBox
                    label={t("label_enroll_secondary_checkbox")}
                    onChange={checkedSecondaryChange}
                    checked={isSecondaryChecked}
                    className="checkLabel"
                    customLabel="checkLabel"
                  />
                </div>
                {isLessThanSixteen &&
                  (isPrimaryChecked || isSecondaryChecked) && (
                    <div className={styles.parentConsentMobile}>
                      <div className={styles.parentContainer}>
                        <span className={styles.parentTextHeading}>
                          {t("label_enrolment_consent_parent_info_title")}{" "}
                        </span>
                        <p className={styles.parentDesc}>
                          {t("label_enrolment_crm_consent_parent_description")}
                        </p>
                      </div>

                      <TGInput
                        isEnroll={true}
                        label={t("label_reset_first_name")}
                        value={enrollmentData.consentParentFirstName}
                        name="consentParentFirstName"
                        onChange={handleChange}
                        isError={!!errors.consentParentFirstName}
                        errorLabel={
                          !!errors.consentParentFirstName &&
                          enrollmentData?.consentParentFirstName?.length === 0
                            ? t("error_parent_middle_name_required")
                            : errors.consentParentFirstName
                        }
                        customClassName={styles.fieldContainer}
                        validation="name"
                        maxLength={40}
                      />
                      <TGInput
                        isEnroll={true}
                        label={t("label_manage_last_name")}
                        value={enrollmentData.consentParentLastName}
                        name="consentParentLastName"
                        onChange={handleChange}
                        isError={!!errors.consentParentLastName}
                        errorLabel={
                          !!errors.consentParentLastName &&
                          enrollmentData?.consentParentLastName?.length === 0
                            ? t("error_reset_last_name_required")
                            : errors.consentParentLastName
                        }
                        customClassName={styles.fieldContainer}
                        validation="name"
                        maxLength={40}
                      />
                    </div>
                  )}

                <div className={styles.stroke}>
                  <TGIcon icon="stroke" size=" " fillColor="" />
                </div>
                <div className={styles.checkSignup}>
                  <TGCheckBox
                    isHtmlContent={true}
                    htmlContent={termConditionData.termConditiontext}
                    onChange={checkedChange}
                    checkref={termConditionRef}
                    checked={isChecked}
                    className="checkLabel"
                    customLabel="checkLabel"
                  />
                  {errors.termsConditions && (
                    <div className={styles.termsConditionsError}>
                      <TGIcon icon="error-icon" fillColor="" size="16" />
                      <div className={styles.termConditionText}>
                        {t("enroll_terms_condition")}
                      </div>
                    </div>
                  )}
                </div>
                <div className={styles.signupButton}>
                  <TGButtonVariants
                    label={
                      RopSIgnupResponse.isLoading
                        ? ""
                        : t("button_reset_continue")
                    }
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    borderRadius="30px"
                    boxShadow="0px 1px 2px 0px rgba(16, 24, 40, 0.05)"
                    bgColor="#684B9B"
                    padding="16px"
                    textColor="#FFFFFF"
                    fontFamily="Inter"
                    fontSize="16px"
                    fontStyle="normal"
                    fontWeight="700"
                    lineHeight="160%"
                    borderDisabled="0.791px solid #E3D4FE"
                    bgColorDisabled="#E3D4FE"
                    boxShadowDisabled="0px 1px 2px 0px rgba(16, 24, 40, 0.05)"
                    bgColorHover="#684b9b"
                    borderHover="0.791px solid #E3D4FE"
                    boxSHadowHover="0px 1px 2px 0px rgba(16, 24, 40, 0.05)"
                    height="50px"
                    width="100%"
                    onClick={handleSubmitEnroll}
                  >
                    {RopSIgnupResponse.isLoading ? (
                      <>
                        <Spinner
                          animation="grow"
                          variant="light"
                          style={{
                            width: "10px",
                            height: "10px",
                            margin: "5px",
                          }}
                        />
                        <Spinner
                          animation="grow"
                          variant="light"
                          style={{
                            width: "10px",
                            height: "10px",
                            margin: "5px",
                          }}
                        />
                        <Spinner
                          animation="grow"
                          variant="light"
                          style={{
                            width: "10px",
                            height: "10px",
                            margin: "5px",
                          }}
                        />
                      </>
                    ) : null}
                  </TGButtonVariants>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};
