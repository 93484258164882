import React, { useEffect, useState } from "react";
import styles from "./signup.module.scss";
import { Enrollment } from "./enrollment";
import useScreenSize from "../../../../utils/hook/useScreenSize";
import CRMSignUp from "./crm-signup";
import { t } from "i18next";
import { useLocation } from "react-router-dom";
import { preferenceFromDataRequest } from "../../slice/newsletterPreferenceSlice";
import { RootState } from "../../slice/RootReducer";
import { useDispatch, useSelector } from "react-redux";

const SignUp = () => {
  const dispatch = useDispatch();
  const preferenceFromResponse = useSelector(
    (state: RootState) => state?.osciReducer?.preferenceFrom.countryList
  );
  const [activeTab, setActiveTab] = useState(0);
  const location = useLocation();
  const isDesktopView: boolean = useScreenSize().deviceSize.width > 767;
  const handleTabClick = (index: any) => {
    setActiveTab(index);
  };
  const { signUpType } = location.state || {};
  useEffect(() => {
    if (!sessionStorage?.getItem("accesstoken")) {
      sessionStorage.clear();
    }
    if (signUpType === "ROP") {
      setActiveTab(0);
    }
    if (signUpType === "CRM") {
      setActiveTab(1);
    }
  }, [signUpType]);

  useEffect(() => {
    dispatch(preferenceFromDataRequest());
  }, [dispatch]);

  return (
    <>
      <>
        {!isDesktopView && (
          <div className={styles.mobileResetContainer}>
            <div className={styles.mobileFirstContainer}>
              <div className={styles.titleContainer}>
                <span className={styles.mobileFirstContainerText}>
                  {t("label_enrolment_consent_signup")}
                </span>

                <span className={styles.mobileDescriptionText}>
                  {t("label_enrolment_consent_description2")}
                </span>
              </div>
            </div>
            <>
              <div className={styles.signUpPrimaryContainer}>
                <div className={styles.signUpSecondaryContainer}>
                  <div className={styles.tabContainer}>
                    <div
                      className={`${
                        activeTab === 0 ? styles.active : styles.default
                      }`}
                      onClick={() => handleTabClick(0)}
                    >
                      <div className={styles.primaryHeading}>
                        {t("label_enrollment_tab_heading1")}
                      </div>
                      <div className={styles.primaryDescription}>
                        {t("label_enrollment_tab_heading2")}
                      </div>
                    </div>
                    <div
                      className={`${
                        activeTab === 1 ? styles.active : styles.default
                      }`}
                      onClick={() => handleTabClick(1)}
                    >
                      <div className={styles.secondaryHeading}>
                        {t("label_enrollment_tab_desc")}
                      </div>
                      <div className={styles.primaryDescription}>
                        {t("label_enrollment_tab_description2")}
                      </div>
                    </div>
                  </div>
                  {activeTab === 0 ? (
                    <div>
                      <Enrollment />
                    </div>
                  ) : (
                    <div>
                      <CRMSignUp />
                    </div>
                  )}
                </div>
              </div>
            </>
          </div>
        )}
        {isDesktopView && (
          <div className={styles.resetContainer}>
            <div className={styles.firstContainer}>
              <div className={styles.titleContainer}>
                <span className={styles.firstContainerText}>
                  {t("label_enrolment_consent_signup")}
                </span>

                <div className={styles.descriptionText}>
                  {t("label_enrolment_consent_description1")}{" "}
                </div>
                <div className={styles.descriptionText}>
                  {t("label_enrolment_consent_description2")}
                </div>
              </div>
            </div>
            <>
              <div className={styles.signUpPrimaryContainer}>
                <div className={styles.signUpSecondaryContainer}>
                  <div className={styles.tabContainer}>
                    <div
                      className={`${
                        activeTab === 0 ? styles.active : styles.default
                      }`}
                      onClick={() => handleTabClick(0)}
                    >
                      <div className={styles.primaryHeading}>
                        {t("label_enrollment_tab_heading1")}
                      </div>
                      <div className={styles.primaryDescription}>
                        {t("label_enrollment_tab_heading2")}
                      </div>
                    </div>
                    <div
                      className={`${
                        activeTab === 1 ? styles.active : styles.default
                      }`}
                      onClick={() => handleTabClick(1)}
                    >
                      <div className={styles.secondaryHeading}>
                        {t("label_enrollment_tab_desc")}
                      </div>
                      <div className={styles.primaryDescription}>
                        {t("label_enrollment_tab_description2")}
                      </div>
                    </div>
                  </div>
                  {activeTab === 0 ? (
                    <div>
                      <Enrollment />
                    </div>
                  ) : (
                    <div>
                      <CRMSignUp />
                    </div>
                  )}
                </div>
              </div>
            </>
          </div>
        )}
      </>
      {isDesktopView && (
        <>
          <br />
          <br />
        </>
      )}
    </>
  );
};

export default SignUp;
